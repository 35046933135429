.flex-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}


.center-footer {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 52%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}


.left-container-footer
{

  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100%;
  width: 280px;
  left: 0;
  bottom: 0;

  //border: solid 2px #eff2f9;


}


.right-container-footer
{
  position: absolute;
  height: 50%;
  width: 200px;



  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  //margin-top: 20px;
  //margin-bottom: 10px;
  top:0;
  padding-top: 25px;


  @media (min-width: 992px) {
    padding-top: 2px;
    height:100%;
    width: 240px;
    right: 0;
    bottom: 0;
  }
  border: solid 2px #eff2f9;



}

.center-container-footer
{
  position: absolute;
  height: 100%;
  width: 350px;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

//align-self: flex-end;

  //padding-top: 5px;


  bottom: 0;
  //margin-top: 14px;
  @media (min-width: 992px) {
    height:100%;
    width: 350px;

    //bottom: 0;
    //justify-content: center;
  }
  //border: solid 2px #eff2f9;



}

.item-bottom-footer {

  @media (min-width: 992px) {
    position: absolute;
    padding-top: 14px;
  }

  //bottom: 0px;
  //margin-bottom: 0px;

}


.icon-properties-footer {
  transition: all .15s ease-in-out;
  fill: rgba(255, 255, 255, 0.92);
  //padding-bottom: 4px;
  @media (min-width: 992px) {
    margin-top: 8px;
  }

  //margin-bottom: 10px;
}

.icon-properties-footer:hover{
  cursor: pointer;
  transition: all .15s ease-in-out;
  fill: rgba(250, 56, 64, 1);
}