@keyframes blink-animation {
  50% {
    opacity: 1;
  }
}

.textA-with-cursor-home::after {
  content: '|';
  color: gray;
  display: inline-block;
  animation-name: blink-animation;
  animation-delay: 0ms;
  animation-duration: 500ms;
  animation-iteration-count: 3;
  opacity: 0;
}

.textB-with-cursor-home::after {
  content: '|';
  color: gray;
  display: inline-block;
  animation-name: blink-animation;
  animation-duration: 500ms;
  animation-delay: 1700ms;
  animation-iteration-count: 3;
  opacity: 0;
}





.rectangle-home {
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  right: 0px;
  //display: inline-block;
  width: 80%;
  height: 100%;
  border: transparent;
  //background: rgba(0, 0, 0, 0.5);
}

.title-container-home {
  position: relative;
  margin: 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  cursor: default;
  z-index: 2;
  //border: solid 2px #222222;


  @media (min-width: 992px) {

    margin-top: 10px;
    margin-left: 0px;
  }
}


@media (max-width: 991px) {


  .header_burger-home {

    display: block;
    position: absolute;
    right: 30px;
    top: 35px;
    width: 30px;
    height: 20px;

    transform: scale(1.12);
    z-index: 3;

  }
  .header_burger-home span {
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 2px;
    left: 0;
    top: 9px;

    transition: all 0.3s ease-in-out 0s;

  }

  .header_burger-home:before,
  .header_burger-home:after {
    content: '';
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;

    transition: all 0.3s ease-in-out 0s;
  }
  .header_burger-home:before {
    top: 0;
  }
  .header_burger-home:after {
    bottom: 0;
  }

  .header_burger-home.active-home span {
    opacity: 0;
  }

  .header_burger-home.active-home:before {
    opacity: 0;

  }
  .header_burger-home.active-home:after {
    opacity: 0;

  }

  .header_burger-home.active-home {
    z-index: 100;
  }
}

.home-visibility {
  //visibility: visible;
  @media (max-width: 991px) {
    z-index: 1005;
  }
  //transition: all 0.3s ease-in-out 0s;
}

.home-visibility.hide-home {
  //visibility: hidden;
  z-index: -2;
  //transition: all 0.3s ease-in-out 0s;
}


.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}

.flex-row-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-style-home {

  display: flex;


  //top: 18px;

  //margin-top: 100px;
  //margin-left: 45px;
  flex-direction: row;
  align-items:center;

  width: 50vw;
  height: 58px;
  border-radius: 10px;

  justify-content: center;

  outline:none;
  text-align: center;

  background: rgba(255, 255, 255, 0.0);
  border: solid 3px var(--color-orange);
  //border: 2px solid transparent;

  letter-spacing:1px;
  color:white;


  font:{
    family: 'ProximaNova-Regular';
    size:5.6vw;
    weight:400;
  }

  cursor: pointer;
  transition: all 0.25s ease;



  &:hover {
    color:black;
    background: white;
    border: 2px solid black;
  }

  @media (min-width: 412px) {
    font-size: 24px;
    width: 376px;

  }

}


.home-planet-animation-container {
  z-index: -1;
  zoom: 140%;

  position: absolute;

  right: 30%;
  top: 20%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media (min-width: 992px) {
    right: 34%;
    top: 20%;

    zoom: 130%;

  }
}

.home-cargo-animation-container {
  zoom: 90%;
  position: absolute;

  right: -5%;
  width: 150%;

  top: 64%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @media (min-width: 992px) {
    top: 60%;
    zoom: 100%;

  }

}

.flex-row-pc-column-mobile-home {
  display: flex;
  flex-direction: column;


  @media (min-width: 992px) {


    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
  }
}