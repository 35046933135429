$basepx: 16px;

@function em($target, $context: $basepx) {
  @if $target == 0 {
    @return 0
  }
  @return $target / $context + 0em;
}

@mixin prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}


.background-stuff {
  position: absolute;
  top: 10vh;
  right: 0;
  //width: 500px;
  //height: 500px;
  overflow: hidden;
  background: transparent;

  //.bg{
  //  position: absolute;
  //  top: 0%;
  //  left: -100%;
  //  width: 50%;
  //  height: 100%;
  //  background: #636ed1;
  //  @include prefix(animation, bgIn 4s 0s linear infinite);
  //  @include prefix(transform, skewX(10deg));
  //  z-index: 1;
  //}
  //
  //.ground{
  //  position: absolute;
  //  bottom: 0;
  //  margin-bottom: em(-30px, $basepx);
  //  height: 50%;
  //  width: 100%;
  //  background: #ffc253;
  //  z-index: 2;
  //}
}

.cargo-animation-container {
  height: 100%;
  width: 100%;
  display: table;

  overflow: hidden;

  .car-wrapper {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
}

.car {
  width: em(320px, $basepx);
  height: em(200px, $basepx);

  position: relative;
  opacity: 1;
  //margin: 0 10px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: 10%;
  @media (min-width: 992px) {
    margin-right: 20%;
  }

  z-indexs: 4;
  @include prefix(animation, carMove 0.3s infinite);

  .body {

    position: absolute;
    top: 0;
    left: em(14px, $basepx);
    width: em(230px, $basepx);
    height: em(160px, $basepx);
    background: #fff;

    &:before {
      content: "";
      position: absolute;
      left: em(-5px, $basepx);
      top: em(-5px, $basepx);

      border-radius: 10px 10px 0 0;
      width: em(249px, $basepx);
      z-index: 2;
      height: em(15px, $basepx);
      background: rgb(94, 94, 94);
    }

    div {
      position: absolute;
      background: #fff;
      width: em(290px, $basepx);
      height: em(60px, $basepx);
      bottom: em(-10px, $basepx);
      border-radius: 0 0 10px 10px;

      &:before {
        content: "";
        background: #fff;
        position: absolute;
        top: em(-29px, $basepx);
        right: 0px;
        width: em(80px, $basepx);
        height: em(40px, $basepx);
        z-index: 1;
        border-radius: 0 14px 0 0;
        @include prefix(transform, rotate(17deg));
      }

      &:after {
        content: "";
        background: #fff;
        position: absolute;
        top: em(-82px, $basepx);
        right: em(17px, $basepx);
        width: em(90px, $basepx);
        height: em(40px, $basepx);
        z-index: 1;
        border-radius: 10px 0 0 0;
        @include prefix(transform, rotate(75deg));
      }
    }
  }

  .wheel {
    position: absolute;
    width: em(60px, $basepx);
    height: em(60px, $basepx);
    background: #5e5e5e;
    border-radius: 50%;
    bottom: 0;
    left: em(48px, $basepx);
    z-index: 14;
    @include prefix(animation, carMove 0.3s 0.2s);

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      background: #fff;
      width: em(24px, $basepx);
      height: em(24px, $basepx);
      border-radius: 50%;
      @include prefix(transform, translate(-50%, -50%));
    }

    &:nth-of-type(2) {
      left: em(210px, $basepx);
    }
  }

  .decos {
    width: em(290px, $basepx);
    height: em(168px, $basepx);
    position: absolute;
    z-index: 12;
    top: 0;
    left: em(14px, $basepx);
  }

  .line-bot {
    position: absolute;
    bottom: em(9px, $basepx);
    height: 3px;
    width: 100%;
    background: #e1e8ef;
  }

  .door {
    position: absolute;
    left: em(120px, $basepx);
    bottom: 0;
    width: em(60px, $basepx);
    height: em(141px, $basepx);
    border-radius: 10px 10px 0 0;
    border: 3px solid #e1e8ef;
    border-bottom: 0;
    background: #fff;

    &:after {
      content: "";
      position: absolute;
      top: em(6px, $basepx);
      left: em(7px, $basepx);
      width: em(46px, $basepx);
      height: em(40px, $basepx);
      border-radius: 4px;
      background: #5bc2ef;
    }

    .handle {
      position: absolute;
      right: em(10px, $basepx);
      top: em(70px, $basepx);
      width: em(10px, $basepx);
      height: em(10px, $basepx);
      background: #e1e8ef;
      border-radius: 50%;

      &:after {
        content: "";
        width: em(20px, $basepx);
        height: em(4px, $basepx);
        border-radius: 10px;
        background: #e1e8ef;
        position: absolute;
        top: 50%;
        left: em(-5px, $basepx);
        margin-top: em(-2px, $basepx);
      }
    }

    .bottom {
      position: absolute;
      bottom: em(6px, $basepx);
      left: em(11px, $basepx);
      width: em(38px, $basepx);
      height: em(12px, $basepx);

      &:before, &:after {
        content: "";
        display: block;
        width: 100%;
        height: em(3px, $basepx);
        background: #e1e8ef;
        height: em(4px, $basepx);
        border-radius: 10px;
        margin-bottom: em(4px, $basepx);
      }
    }
  }

  .window {
    position: absolute;
    top: em(18px, $basepx);
    left: em(200px, $basepx);
    width: em(32px, $basepx);
    background: #5bc2ef;
    height: em(72px, $basepx);
    border-radius: 10px 10px 0 10px;

    &:before {
      content: "";
      width: 100%;
      height: em(20px, $basepx);
      background: #5bc2ef;
      position: absolute;
      bottom: 0;
      left: 0;
      width: em(47px, $basepx);
      border-radius: 0 10px 10px 10px;
    }

    &:after {
      content: "";
      height: em(20px, $basepx);
      background: #5bc2ef;
      position: absolute;
      top: em(17px, $basepx);
      left: em(-13px, $basepx);
      width: em(67px, $basepx);
      transform-origin: bottom;
      @include prefix(transform, rotate(74deg));
      border-radius: 10px 10px 10px 10px;
    }
  }

  .light {
    position: absolute;
    width: em(10px, $basepx);
    height: em(10px, $basepx);
    background: #ff8c40;
    right: em(60px, $basepx);
    bottom: em(50px, $basepx);
    border-radius: 3px;
  }

  .light-front {
    position: absolute;
    width: em(8px, $basepx);
    height: em(8px, $basepx);
    background: #ff8c40;
    right: em(-3px, $basepx);
    bottom: em(57px, $basepx);
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #ff8c40;
      top: 100%;
      height: em(15px, $basepx);
      border-radius: 3px;
      margin-top: 2px;
    }
  }

  .antenna {


    position: absolute;
    width: em(26px, $basepx);
    height: em(10px, $basepx);
    background: #fff;
    bottom: 100%;
    left: em(17px, $basepx);
    top: em(-14px, $basepx);
    border-radius: 4px 4px 0 0;

    &:before {
      content: "";
      position: absolute;
      width: em(38px, $basepx);
      height: em(4px, $basepx);
      background: #fff;
      bottom: 0;
      left: em(-22px, $basepx);
      @include prefix(transform, rotate(55deg));
      @include prefix(transform-origin, right);
      border-radius: 4px;
    }
  }

  .cargo-logo-container {
    position: absolute;
    left: em(30px, $basepx);
    top: em(30px, $basepx);
    width: em(100px, $basepx);
    height: em(40px, $basepx);
    border: 3px solid var(--color-orange-light);
    //border-radius: 10px;
    //@include prefix(transform, rotate(-30deg));
    display: table;

    .cone {
      position: absolute;
      top: 50%;
      left: 50%;
      @include prefix(transform, translate(-50%, -20%));
      border-right: em(18px, $basepx) solid transparent;
      border-left: em(18px, $basepx) solid transparent;
      border-top: em(40px, $basepx) solid #f4b224;

      &:after {
        content: "";
        position: absolute;
        width: em(20px, $basepx);
        height: em(20px, $basepx);
        background: #00c0a8;
        border-radius: 50%;
        top: em(-55px, $basepx);
        left: 0px;
        z-index: -1;
      }

      &:before {
        content: "";
        position: absolute;
        width: em(20px, $basepx);
        height: em(20px, $basepx);
        background: #ffd132;
        border-radius: 50%;
        top: em(-55px, $basepx);
        left: em(-20px, $basepx);
        z-index: -1;
        box-shadow: 10px -5px 0px 0px #ff9a9a;
      }
    }
  }
}

.cargo-custom-text {
  display: table-cell;
  padding: 2px 12px;
  vertical-align: middle;
  text-align: center;

  font-size: em(20px, $basepx);
  font-weight: 600;
  color: var(--color-orange);
}

.cargo-custom-subtext {
  position: absolute;

  top: em(88px, $basepx);
  left: em(20px, $basepx);

  display: table-cell;
  padding: 2px 12px;
  vertical-align: bottom;
  text-align: left;

  line-height: 0.9;

  font-size: em(14px, $basepx);
  font-weight: 600;
  color: rgb(185, 185, 185);

}

.wind {
  position: absolute;
  z-index: -1;
  right: 100%;
  height: 100%;
  margin-right: em(-20px, $basepx);

  .p {
    position: absolute;
    background: #fff;
    border-radius: em(10px, $basepx);
    height: em(10px, $basepx);
  }

  .p1 {
    width: em(70px, $basepx);
    position: absolute;
    right: 0;
    top: em(25px, $basepx);
    animation: wind 1s -1s linear infinite;
  }

  .p2 {
    width: em(100px, $basepx);
    right: em(40px, $basepx);
    top: em(60px, $basepx);
    animation: wind 1s -2s linear infinite;
  }

  .p3 {
    width: em(50px, $basepx);
    right: em(00px, $basepx);
    top: em(105px, $basepx);
    animation: wind 1s -1s linear infinite;
  }

  .p4 {
    width: em(60px, $basepx);
    right: em(100px, $basepx);
    top: em(135px, $basepx);
    animation: wind 1s 1s linear infinite;
  }

  .p5 {
    width: em(70px, $basepx);
    right: em(30px, $basepx);
    top: em(145px, $basepx);
    animation: wind 1s -1.5s linear infinite;
  }
}

.car-wrapper_inner {
  position: relative;
  z-index: 4;
}


@keyframes carMove {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes wind {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(-400%);
    opacity: 0;
  }
}

.car_outter {
  @include prefix(transform, translateX(-100%));
  position: relative;
  animation: carDrive 15s cubic-bezier(0.8, 0.08, 0.04, 1.0) infinite;
}

@keyframes carDrive {
  0% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(0%);
  }
  70% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(200%);
  }
}

@keyframes bgIn {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

@keyframes wrapperIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.cargo-custom-box {
  width: 100%;
  height: 100%;

  background: None !important;
  box-shadow: None !important;

  padding: 10px 0;
  //margin: 100px 100px;
  transform: rotate(-8deg);

}