$angMax: 23.4;


.earth-container {
  z-index: -1;
  $earthWidth: 300px;
  @keyframes rotate {
    from {
      background-position: 0 0;
    }
    to {
      background-position: #{$earthWidth * 2.5} 0;
    }
  }

  position: absolute;
  //top: 0;
  //:0;
  //margin: 210px 50px 120px calc(50% - #{$earthWidth / 2});
  height: $earthWidth;
  background: linear-gradient(
                  90deg,
                  #222 #{$earthWidth / 2},
                  #0000 #{$earthWidth / 2}
  ),
  linear-gradient(#0000 0, #111 0.5%, #111 98.5%, #0000 100%);
  cursor: pointer;

  .globe {
    position: absolute;
    width: $earthWidth;
    height: 100%;
    border-radius: 100%;
    background: url('./imgs/earth1.png') 0 0 / 250% 100%, #1c7f9d;

    animation: rotate 40s infinite linear;
    transform: rotate(-10deg);


  }

  .day-label {
    position: absolute;
    left: 0;
    z-index: 10;
    width: 50px;
    text-align: right;
    color: #dedede;
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      inset: 50% -#{$earthWidth * 0.68} 50% 145%;
      border-bottom: 2px dotted #dedede;
      border-top: 2px dotted #383977;
      z-index: 10;
    }

    &.north-label {
      top: 8%;
    }

    &.south-label {
      bottom: 10%;
    }
  }

  .shadow {
    position: absolute;
    width: $earthWidth;
    height: 100%;
    z-index: 20;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      inset: -200px 0;
      clip-path: circle(#{($earthWidth / 2) + 1px});
      box-shadow: inset -180px 0 20px 0 #000d;
      border-radius: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      inset: 100% 50% -80px;
      width: 1px;
      border-left: 2px solid #fff8;
    }

    .label {
      position: absolute;
      height: 16px;
      width: fit-content;
      bottom: -55px;
      left: calc(50% + 8px);
      color: #dedede;



    }
  }
}

.orbit-container {
  position: relative;
  width: 650px;
  margin: 0 auto;
  max-width: calc(100vw - 80px);
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 37px 0 auto 0;
    height: 8px;
    border-radius: 100%;
    border: 2px dashed #fff8;
  }

  &:before {
    border-bottom-width: 0;
    z-index: -10;
  }

  &:after {
    border-top-width: 0;
    z-index: 5;
  }

  .earth {
    $c-earth: #8686bd;
    $c-earth-d: #393877;

    @keyframes earthBg {
      0% {
        background: linear-gradient(90deg, $c-earth 50%, $c-earth-d 50%);
      }
      49.9% {
        background: linear-gradient(90deg, $c-earth 50%, $c-earth-d 50%);
      }
      50% {
        background: linear-gradient(-90deg, $c-earth 50%, $c-earth-d 50%);
      }
      100% {
        background: linear-gradient(-90deg, $c-earth 50%, $c-earth-d 50%);
      }
    }

    @keyframes earthFg {
      0% {
        transform: scale(1, 1);
        background: $c-earth;
      }
      24.9% {
        transform: scale(0, 1);
        background: $c-earth;
      }
      25% {
        transform: scale(0, 1);
        background: $c-earth-d;
      }
      49.9% {
        transform: scale(-1, 1);
        background: $c-earth-d;
      }
      50% {
        transform: scale(1, 1);
        background: $c-earth-d;
      }
      74.9% {
        transform: scale(0, 1);
        background: $c-earth-d;
      }
      75% {
        transform: scale(0, 1);
        background: $c-earth;
      }
      100% {
        transform: scale(-1, 1);
        background: $c-earth;
      }
    }

    position: absolute;
    top: 10px;
    left: calc((var(--ang) * 50%) + 50% - 30px);
    z-index: var(--ang-z);
    border-radius: 100%;
    padding: 30px;
    animation: earthBg linear infinite;
    animation-duration: 1s;
    //animation-delay: calc((1s * var(--ang-full)) - 1.75s);
    animation-play-state: paused;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      inset: -15px 50%;
      width: 1px;
      transform: rotate(-23.4deg);
      border-left: 1px solid #fff8;
      z-index: 20;
    }

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 50%;
      animation: earthFg linear infinite;
      animation-duration: inherit;
      animation-delay: inherit;
      animation-play-state: inherit;
    }
  }

  .sun {
    position: absolute;
    top: 0;
    z-index: 5;
    left: calc(50% - 40px);
    border-radius: 100%;
    background-color: #dbae2d;
    padding: 40px;
    pointer-events: none;
  }

  .labels {
    padding: 120px 0 40px;

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &:first-child {
        span {
          display: inline-block;
          font-style: normal;
          border-bottom: 1px solid #fff8;
          padding: 0 3px;
          height: 16px;
        }
      }

      &:last-child {
        padding: 4px;
        font-size: 14px;
      }
    }
  }
}