
.button-style-contacts {
  z-index: 10;
  display: flex;

  flex-direction: row;
  align-items:center;

  margin: 16px 0 !important;

  width: 40vw;
  height: 40px;
  border-radius: 10px;

  justify-content: center;

  outline:none;
  text-align: center;

  background: rgba(255, 255, 255, 0.0);
  border: solid 2px rgba(247, 139, 75, 1);
  //border: 2px solid transparent;

  letter-spacing:1px;
  color: #4f4f4f;


  font:{
    family: 'ProximaNova-Regular';
    size: 4.6vw;
    weight:400;

  }

  cursor: pointer;
  transition: all 0.25s ease;


  &:hover {
    color:black;
    background: white;
    border: 2px solid black;
  }

  @media (min-width: 512px) {
    font-size: 18px;
    width: 166px;

  }
}

.contact-form {
  z-index: 1;

  display: flex;
  flex-direction: column;


  @media (min-width: 912px) {
    width: 20vw;
  }
}

.contact-form * {
  margin: 10px 0;
}

.contact-form-custom-box {
  zoom: 110%;

  margin: 40px 20px;
  padding: 14px 0;
  @media (min-width: 912px) {
    zoom: 120%;
    padding: 10px 40px;
    margin: 0 20px;
  }
}

.contact-form-custom-box-title {
  padding: 14px 0;
}



.contact-form-custom-background {
  opacity: 1;

  background: blueviolet;
  color: blueviolet;

  transition: all 0.3s;
}

.contact-form-is-unsent {
  position: absolute;
  height: 100%;
  width: 100%;

  opacity: 0;

  z-index: 0;
}
