@font-face {
  font-family: "BlenderPro-Medium";
  src: url("./fonts/BlenderPro-Medium.eot"); /* IE9 Compat Modes */
  src: url("./fonts/BlenderPro-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/BlenderPro-Medium.otf") format("opentype"), /* Open Type Font */
  url("./fonts/BlenderPro-Medium.svg") format("svg"), /* Legacy iOS */
  url("./fonts/BlenderPro-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/BlenderPro-Medium.woff") format("woff"), /* Modern Browsers */
  url("./fonts/BlenderPro-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: "ProximaNova-Regular";
  src: url("./fonts/ProximaNova-Regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/ProximaNova-Regular.otf") format("opentype"), /* Open Type Font */
  url("./fonts/ProximaNova-Regular.svg") format("svg"), /* Legacy iOS */
  url("./fonts/ProximaNova-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/ProximaNova-Regular.woff") format("woff"), /* Modern Browsers */
  url("./fonts/ProximaNova-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "BlenderPro-Thin";
  src: url("./fonts/BlenderProThin.eot"); /* IE9 Compat Modes */
  src: url("./fonts/BlenderProThin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/BlenderProThin.otf") format("opentype"), /* Open Type Font */
  url("./fonts/BlenderProThin.svg") format("svg"), /* Legacy iOS */
  url("./fonts/BlenderProThin.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/BlenderProThin.woff") format("woff"), /* Modern Browsers */
  url("./fonts/BlenderProThin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BlenderPro-Book";
  src: url("./fonts/BlenderProBook.eot"); /* IE9 Compat Modes */
  src: url("./fonts/BlenderProBook.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./fonts/BlenderProBook.otf") format("opentype"), /* Open Type Font */
  url("./fonts/BlenderProBook.svg") format("svg"), /* Legacy iOS */
  url("./fonts/BlenderProBook.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./fonts/BlenderProBook.woff") format("woff"), /* Modern Browsers */
  url("./fonts/BlenderProBook.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 14px;

  //font-weight: 500%;
}

body {
  font-family: 'BlenderPro-Medium', sans-serif;
  //letter-spacing: -2px;
}

a,
a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    min-width: 100%;
  }
}