.center-approach {
  font-size: 70px;
  color: white;

  text-align: center;
  position: absolute;
  top: 50%;
  left: 52%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}


.row-container-approach {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;


  @media (min-width: 992px) {

    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
}

.approach-custom-box {
  padding: 14px 0 !important;
  background: None !important;
  box-shadow: None !important;
  @media (min-width: 992px) {
  }
}


.approach-custom-box-title {
    color: white !important;
}

.approach-custom-text {
  color: #b0b0b0 !important;
}

