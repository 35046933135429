
.about-custom-container {
  margin: 0 0;
  width: 100%;

  display: flex;
  flex-direction: row;

}

.row-container-about {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;


  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

  }
}

.about-custom-box {
  padding: 40px 0 !important;

  //height: 60vh;
  @media (min-width: 992px) {
    width: 300px !important;
    height: 380px !important;


    justify-content: flex-start !important;


  }
}

