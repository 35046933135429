@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";
@import "base/typography";

section {
  +section {
    +section {
      background-color: #fff;
      position: absolute;
      height: 100vh;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//body {
//  overflow-x: hidden;
//}
//

body {
  //background-color: #0a3a40;
  //text-size-adjust: none;
  //-webkit-text-size-adjust: none;
  background: linear-gradient(180deg, #0a3a40, #0d5862);

  @media (min-width: 992px) {
    background: linear-gradient(180deg, #072126, #0a3a40, #0d5862);
  }

    //background-size: 400% 5vw;
  //animation: gradient 30s ease infinite;
  //height: 100vh;
}

//*:hover {
//  outline: #f68a4b solid 1px;
//}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}


.delim {
  border-top: dashed 2px;
  color: rgba(255, 255, 255, 0.3);

}

