* {
  margin: 0;
  padding: 0;
}


.navbar-header {
  font-family: 'BlenderPro-Book', serif;
  position: fixed;
  // opacity: 0;
  //visibility: visible;
  top: 0;
  left: 0;
  z-index: 1004;


  width: 100%;
  // backdrop-filter: blur(10px);
  visibility: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

     //background-color: rgba(255, 255, 255, 0.6);
    //background-color: rgba(34, 34, 34, 0.6);
    //backdrop-filter: blur(5px);
    //visibility: hidden;

    z-index: -1;
  }
}


.header_body-header {
  position: relative;

  height: 60px;

  align-items: center;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  //border: white 2px solid;



}

.header_list-header {
  //margin-top: 20px;
  height: 100%;
  margin: 0 auto;


  //border: white 2px solid;
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  z-index: 2;
}

.header_list-header li {
  list-style: none;

  margin: 0 0 0 30px ;
}


.header_logo-header {
  flex: 0 0 50px;
  position: relative;
  z-index: 3;
}

.header_logo-header img {
  max-width: 100%;
}


.header_link-header {
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  font-size: 20px;
  text-decoration: none;

  font-weight: 500;


  transition: all 0.2s ease-in-out 0s;
}

.header_link-header:hover {
  color: var(--color-orange);

  transition: all 0.14s ease-in-out 0s;
}

.container-header {
  max-width: 1300px;

  margin: 0 auto;
  padding: 0 10px;

  //border: white 2px solid;

}


.header_burger-header {
  display: none;
}

//.header_menu-header {
//  display: none;
//}


@media (max-width: 991px) {

  body.lock-header {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .header_body-header {
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .header_burger-header {

    display: block;
    position: relative;

    margin-right: 6px;
    width: 32px;
    height: 20px;

    z-index: 3;

  }
  .header_burger-header span {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 2px;
    left: 0;
    top: 9px;

    //transition: all 0.3s ease-in-out 0s;

  }

  .header_burger-header:before,
  .header_burger-header:after {
    content: '';
    background-color: white;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;

    transition: all 0.1s ease-in-out 0s;
  }
  .header_burger-header:before {
    top: 0;
  }
  .header_burger-header:after {
    bottom: 0;
  }

  .header_burger-header.active-header span {

    transform: scale(0);
  }

  .header_burger-header.active-header:before {
    transform: rotate(45deg);
    background-color: white;

    top: 9px;


  }
  .header_burger-header.active-header:after {
    transform: rotate(-45deg);
    background-color: white;
    bottom: 9px;
    right: 30px;
    //bottom: 35px;

  }

  .header_burger-header.active-header {
    z-index: 100;
  }


  .header_menu-header {
    position: fixed;
//z-index: 10000000000;
    //border: white 2px solid;

    //right: -100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: white;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(12px);
    opacity: 0;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    //display: none;
    //text-align: center;

    //overflow: no-display;
    transition: all 0.18s ease-in-out 0s;




  }

  .header_menu-header.active-header {
    top: 0;
    //right: 0;
    overflow: auto;
    visibility: visible;
    //z-index: 4;
    opacity: 1;
    //z-index: 20;
    //transition: all 0.18s ease-in-out 0s;
    //left: 0;
  }


  .header_list-header {

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: white 2px solid;
  }


  .header_list-header li {
    position: relative;

    list-style: none;

    //padding: 0 0;

    margin: 0 0 8px 0;


  }

  .header_link-header {
    //border: white 2px solid;


    font-size: 48px;
    font-weight: 500;
  }


}


.navbarActive-header {
  visibility: visible;
  opacity: 1;
  transition: all 0.44s;
}

.navbarHidden-header {
  visibility: hidden;
  opacity: 0.9;
  transition: all 0.38s;
  transform: translateY(-100%);
}