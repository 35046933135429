



@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
.hvr-ripple-out-contacts {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-ripple-out-contacts:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.hvr-ripple-out-contacts:hover:before, .hvr-ripple-out:focus:before, .hvr-ripple-out:active:before {
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
}


.contacts-custom-box {


  padding: 14px 0 !important;
  background: None !important;
  box-shadow: None !important;
  @media (min-width: 992px) {
    zoom: 150%;
  }
}

.contacts-custom-box * {
  cursor: pointer !important;
}

.contacts-custom-box-title {
  color: white !important;
  @media (min-width: 992px) {

  }
}


.contacts-custom-text {
  padding: 4px 0 !important;
  color: #b0b0b0 !important;
}



.contacts-mails-container {
  margin: 15px 0;
  @media (min-width: 992px) {
  }
}

.flex-row-pc-column-mobile {
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    //width: 100%;
    align-items: center;
    justify-content: space-around;
  }
}